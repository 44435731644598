export enum EmployeeStatus {
  Working = "working",
  Remote = "remote",
  Resting = "resting",
  Holiday = "holiday",
  SickDay = "sickDay",
  Offline = "offline"
}

export class EmployeeStatusType {
  label: string;
  value: EmployeeStatus;
  static types: EmployeeStatusType[] = [
    { value: EmployeeStatus.Working, label: 'Trabajando' },
    { value: EmployeeStatus.Remote, label: 'Teletrabajo' },
    { value: EmployeeStatus.Resting, label: 'Descanso' },
    { value: EmployeeStatus.Holiday, label: 'Vacaciones' },
    { value: EmployeeStatus.SickDay, label: 'Baja' },
    { value: EmployeeStatus.Offline, label: 'Fuera de jornada' },
  ]
  static mapStatus(status: EmployeeStatus): string {
    return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
  }
}

