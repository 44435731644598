export enum DeviceType {
  WallPanel = "wallPanel",
  MobileApp = "mobileApp",
  Backoffice = "backoffice"
}

export class DeviceTypeType {
  label: string;
  value: DeviceType;
  static types: DeviceTypeType[] = [
    { value: DeviceType.WallPanel, label: 'Pared' },
    { value: DeviceType.MobileApp, label: 'Móvil' },
    { value: DeviceType.Backoffice, label: 'Web' },
  ]
  static mapType(status: DeviceType): string {
    return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
  }
}
