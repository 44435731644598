import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { AppearanceService } from './shared/appearance.service';
import { Theme } from './core/enums/theme-type';
import { LogRocketService } from './core/services/logrocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuMode: string = 'sidebar';
  layout: string = 'blue';
  theme: string = 'blue';
  ripple: boolean;
  public colorScheme: string;

  constructor(private primengConfig: PrimeNGConfig,
    private logRocketSercice: LogRocketService,
    private translateService: TranslateService,
    public appearanceService: AppearanceService,
    public afAuth: AngularFireAuth) {
    this.translateService.setDefaultLang('es-ES');
  }
  ngOnInit() {
    this.appearanceService.loadConfiguration().subscribe({
      next: () => {
        this.colorScheme = this.appearanceService.colorScheme ?? Theme.Dark;
      }
    });

    this.primengConfig.ripple = true;
    this.translate("es-ES");
    this.ripple = true;
    this.logRocketSercice.init();
    if (environment.useEmulators)
      this.afAuth.useEmulator('http://localhost:19099');
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }


}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
