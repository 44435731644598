//Firebase
export enum Permission {
  Admin = 'admin',
  Employee = 'employee',
  CompanyManager = 'company-manager',
  WorkplaceManager = 'workplace-manager'
}


//Api
export enum EmployeePermission {
  Employee = "employee",
  CompanyManager = "companyManager",
  WorkPlaceManager = "workplaceManager"
}

export class EmployeePermissionType {
  label: string;
  value: EmployeePermission;
  static types: EmployeePermissionType[] = [
    { value: EmployeePermission.Employee, label: 'Empleado' },
    { value: EmployeePermission.WorkPlaceManager, label: 'Responsable centro de trabajo' },
    { value: EmployeePermission.CompanyManager, label: 'Responsable compañía' },

  ]
  static mapType(type: EmployeePermission): string {
    return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
  }
}