
export enum DayOffStatus {
    Pending = "pending",
    Approved = "approved",
    Rejected = "rejected",
    Renounced = "renounced",//Cancelar 
}

export class DayOffStatusType {
    label: string;
    value: DayOffStatus;
    icon: string;
    iconNotification: string;
    labelNotification: string;
    severity: string;
    static types: DayOffStatusType[] = [
        {
            value: DayOffStatus.Pending, label: 'Pendiente', icon: "pi pi-info-circle", severity: ""
            , labelNotification: "", iconNotification: "pi pi-question-circle"
        },
        {
            value: DayOffStatus.Approved, label: 'Aprobado', icon: "pi pi-check", severity: "success"
            , labelNotification: "Aprobadas", iconNotification: "pi pi-check-circle"
        },
        {
            value: DayOffStatus.Rejected, label: 'Rechazado', icon: "pi pi-exclamation-triangle", severity: "danger"
            , labelNotification: "Rechazadas", iconNotification: "pi pi-times-circle"
        },
        {
            value: DayOffStatus.Renounced, label: 'Cancelado', icon: "pi pi-times", severity: "warning"
            , labelNotification: "Canceladas", iconNotification: "pi pi-exclamation-circle"
        },
    ]


    static mapStatus(type: DayOffStatus): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
    }
    static mapStatusIcon(type: DayOffStatus): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.icon)[0];
    }
    static mapNotificationIcon(type: DayOffStatus): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.iconNotification)[0];
    }
    static mapNotifcationStatus(type: DayOffStatus): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.labelNotification)[0];
    }
    static mapStatusSeverity(type: DayOffStatus): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.severity)[0];
    }
}