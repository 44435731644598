
export enum DayOffType {
    Holiday = "holiday",
    SickDay = "sickDay",
    PersonalDay = "personalDay",
    PaidLeaveDay="paidLeaveDay"
}

export class DayOffTypeType {
    label: string;
    value: DayOffType;
    static types: DayOffTypeType[] = [
        { value: DayOffType.Holiday, label: 'Vacaciones' },
        { value: DayOffType.PersonalDay, label: 'Personal' },
        { value: DayOffType.SickDay, label: 'Enfermedad' },
        { value: DayOffType.PaidLeaveDay, label: 'Permiso retribuído' }
    ]
    static mapType(type: DayOffType): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
    }
}
